<template>
    <div class="">
        
        <div class="py-2 px-2 text-sm">  {{ $t('pages.block_admin.gap_validation_attribute_set_to_true') }}  </div>
        <div class="py-2 px-2">
            <div class="py-2 flex justify-between items-center">
                <label for="" class="font-semibold">{{ $t('pages.block_admin.valid_period_(days)') }} </label>
                <input min=0 v-model="gap_validation.validPeriod"  class="border border-gray-400 rounded-lg px-2 w-40"  type="number" />
            </div>
            <div class="py-2 flex justify-between items-center">
                <label for="" class="font-semibold">{{ $t('pages.block_admin.history_(months)') }}</label>
                <input min=0 v-model="gap_validation.history" class="border border-gray-400 rounded-lg px-2 w-40"  type="number" />
            </div>
        </div>
    </div>
</template>

<script>
// import Loader from "@shared/loader";

export default {    
    name: "gap-validation",
    props: {
        gap_validation:{
            type:Object,
            default: () => {}
        }
    },
    components: {
        
    },
    data() {
        return {
        };
    },
    methods: {},
    computed: {},
};
</script>

<style style="scss" scoped></style>
