<template>
    <div class="shadow overflow-hidden rounded border border-solid border-gray-300 mt-4">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{{ $t('pages.block_admin.report_visibility') }}</th>
                </tr>
            </thead>
            <tbody v-if="isLoading">
                <tr>
                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="5">
                        <Loader class="my-5" height="16px" width="16px" />
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="allReportsRoles && allReportsRoles.length > 0">
                <tr v-for="role in allReportsRoles" :key="role.id + role.name">
                    <td class="px-1 reportVisibltyName" colspan="5">
                        <div class="form-control">
                            <label class="label justify-start cursor-pointer gap-x-2">
                                <input type="radio" class="flex-shrink-0 h-6 w-6 text-dvbrandcolor" :name="role.id" :value="role.id" v-model="reportVisibility" />
                                <span class="label-text">{{ role.name }}</span>
                            </label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Loader from "@shared/loader";

export default {
    name: "report-visibility",
    props: {
        allReportsRoles:{
            type:Array,
            default: () => []
        },
        isLoading:{
            type:Boolean,
            default: false
        },
        value: {
            type: Object,
            default: null
        },
    },
    components: {
        Loader,
    },
    data() {
        return {}
    },
    computed: {
        reportVisibility: {
            get() {
                return this.value?.id || null;
            },
            set(val) {
                const role = this.allReportsRoles.find(el => el.id === val);
                this.$emit('selectReportVisibility', role)
            }
        }
    },
    methods: {},
};
</script>

<style style="scss" scoped>
    .reportVisibltyName {
        padding: 2px 0.25rem !important;
    }
</style>
