<template>
    <div class="shadow overflow-hidden rounded border border-solid border-gray-300">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="py-2 pl-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{{ $t('pages.block_admin.role') }} </th>
                    <th scope="col" :title="$t('pages.block_admin.read')" class="py-2 pl-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><font-awesome-icon icon="address-book" /></th>
                    <th scope="col" :title="$t('pages.block_admin.write')" class="py-2 pl-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><font-awesome-icon icon="edit" /></th>
                    <th v-if="currentTab!='check-output-form-editor'" scope="col" :title="$t('pages.block_admin.visible')" class="py-2 pl-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><font-awesome-icon icon="eye" /></th>
                    <th scope="col" :title="$t('pages.block_admin.mandatory')" class="py-2 pl-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><font-awesome-icon icon="check" /></th>
                </tr>
            </thead>
            <tbody v-if="isLoading">
                <tr>
                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="5">
                        <Loader class="my-5" height="16px" width="16px" />
                    </td>
                </tr>
            </tbody>
            <tbody  v-else-if="roles && roles.length > 0">
                <tr v-for="role in roles" :key="role.id">
                    <td class="px-2 py-1 text-sm flex items-center gap-2"><Checkbox @input="$emit('selectAllRoles', role)" v-model="role.select_all" /> {{ role.title }}</td>
                    <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500">
                        <Checkbox @input="$emit('selectPermission', role)" :disabled="role.permissions.role_write || role.permissions.role_mandatory" v-model="role.permissions.role_read" />
                    </td>
                    <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500">
                        <Checkbox @input="$emit('selectPermission', role)" :disabled="role.permissions.role_mandatory" v-model="role.permissions.role_write" />
                    </td>
                    <td  v-if="currentTab!='check-output-form-editor'" class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500">
                        <Checkbox @input="$emit('selectPermission', role)" :disabled="role.permissions.role_write || role.role_mandatory || role.role_read" v-model="role.permissions.role_visible" />
                    </td>
                    <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500">
                        <Checkbox @input="$emit('selectPermission', role)" v-model="role.permissions.role_mandatory" />
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="5">{{ $t('pages.block_admin.no_data_available') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Loader from "@shared/loader";
import Checkbox from "@/components/checkbox";
import { cloneDeep } from "lodash";

export default {
    name: "block-and-field-permissions",
    props: {
        allRoles: {
            type: Array,
            default: () => [],
        },
        allAvailableRoles: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        currentTab: {
            type: String,
            default: () => ''
        }
    },
    components: {
        Loader,
        Checkbox,
    },
    methods: {},
    computed: {
        roles() {
            let roles_order = {
                'Analyst': 0,
                'Subject': 1,
                'Client': 2,
                'Referee': 3,
            };
            let allRoles = cloneDeep(this.allRoles);
            allRoles = allRoles.map((role) => ({
                ...role, 
                ...this.allAvailableRoles.find((rl) => rl.id === role.role_id)
            }));
            const role_list = allRoles.sort((a, b) => {
                return roles_order[a.title] > roles_order[b.title] ? 1: -1;
            })

            return role_list || [];
        },
    },
};
</script>

<style style="scss" scoped></style>
